import React, { useState } from "react";
import { graphql, Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image";
import Seo from "../components/SEO/SEO"
import PortableText from "../components/portableText";
import Footer from "../components/footer"

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      defaultImage {
        asset {
          _id
        }
      }
    }
    sanityAbout {
        description
      _rawOurStory
      _rawTheServive
      _rawTheServiveAside
      _rawGetInTouch
      _rawStaffIntro
      ourStoryImage {
        ...Image
      }
      staff {
        ...Image
        name
        role
      }
    }
  }
`

const AboutPage = (props) => {

  const { data } = props
  const site = (data || {}).site
  const [open, setOpen] = useState(false);

  const StaffMember = ({ item }) => (
    <>
    <div>
      <div className="rounded-full relative overflow-hidden">
        <span className="rounded-full bg-[#1C6DB5] mix-blend-screen z-10 absolute top-0 left-0 w-full h-full"></span>
        <Image
          {...item}
          width={800}
          height={800}
          alt=" "
        />
      </div>
      <div className="uppercase text-center text-[15px] leading-[30px] tracking-[0.3em] my-8 md:mt-16 md:mb-0">
        <h4>{item.name}</h4>
        <h4>{item.role}</h4>
      </div>
    </div>
    </>
  );

  return (
    <>
      <Seo
        title={site.title}
        description={data.sanityAbout.description}
        image={site.defaultImage}
      />
      <header className="container z-10">
        <div className="flex lg:grid lg:grid-cols-2 gap-4 pt-6 lg:pt-16 lg:pb-[5px] lg:gap-16 items-start text-[#1C6DB5]">
          <Link className="logo" to="/"><span className="sr-only">Dimpat</span></Link>
          <button
            className="ml-auto flex lg:hidden uppercase -mt-[6px] tracking-[0.3em]"
            onClick={() => {
              setOpen(!open);
            }}
          >Menu</button>
          <nav className="hidden lg:flex lg:ml-auto gap-4 lg:gap-8 uppercase text-[15px] tracking-[0.1em] lg:tracking-[0.3em]">
            <Link to="/projects">Projects</Link>
            <Link to="/about-us">About us</Link>
            <Link to="/get-in-touch">Get in touch</Link>
          </nav>
        </div>
        <span className="w-full h-[2px] bg-[#1C6DB5] inline-block"></span>
      </header>
      <div className={open ? `fixed bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20` : `fixed invisible bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20`}>
        <div className="p-6 flex flex-col relative h-full">
          <button
              className="uppercase absolute right-4 -mt-[6px] tracking-[0.3em]"
              onClick={() => {
                setOpen(!open);
              }}
            >Close</button>
          <ul className="mt-16 w-screen uppercase text-[25px] tracking-[0.1em] mobile">
            <li className="mb-5"><Link className="mb-5 inline-block" to="/projects">Projects</Link></li>
            <li className="mb-5"><Link className="mb-5 inline-block" to="/about-us">About us</Link></li>
            <li className="mb-5"><Link to="/get-in-touch">Get in touch</Link></li>
          </ul>
          <ul className="mt-auto flex socials gap-8 uppercase text-[15px] tracking-[0.3em]">
            <li>
              <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Instagram</a>
            </li>
            <li>
              <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Facebook</a>
            </li>
          </ul>
        </div>
      </div>
      <main>
        <div className="container my-8 md:my-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16 pb-12 md:pb-24">
            <div>
              <h1 className="hidden invisible">
                Our Story
              </h1>
              {data.sanityAbout.ourStoryImage && (
                <Image
                  {...data.sanityAbout.ourStoryImage}
                  width={1000}
                  height={1000}
                  className="rounded-full"
                  alt=" "
                />
              )}
            </div>
            {data.sanityAbout._rawOurStory && (
              <PortableText className="copy mt-8 md:mt-0" blocks={data.sanityAbout._rawOurStory} />
            )}
          </div>
          <span className="w-full h-[2px] bg-[#1C6DB5] inline-block"></span>
        </div>
        <div className="container my-8 md:my-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16 pb-12 md:pb-24">
            <div className="flex flex-col h-full">
              <h2 className="mb-2 md:mb-16 text-[34px] leading-[44px] lg:text-[70px] lg:leading-[80px]">
                The Service
              </h2>
              {data.sanityAbout._rawTheServiveAside && (
                <div className="hidden md:flex aside mt-auto">
                  <PortableText blocks={data.sanityAbout._rawTheServiveAside} />
                </div>
              )}
            </div>
            <div>
            {data.sanityAbout._rawTheServive && (
              <PortableText className="copy" blocks={data.sanityAbout._rawTheServive} />
            )}
            {data.sanityAbout._rawTheServiveAside && (
                <div className="flex md:hidden aside mt-8">
                  <PortableText blocks={data.sanityAbout._rawTheServiveAside} />
                </div>
              )}
            </div>
          </div>
          <span className="w-full h-[2px] bg-[#1C6DB5] inline-block"></span>
        </div>
        <div className="container  my-12 md:my-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16">
            <h2 className="text-[34px] leading-[44px] lg:text-[70px] lg:leading-[80px]">
              The Team
            </h2>
            <div className="copy text-[26px] leading-[38px] lg:text-[35px] lg:leading-[48px]">
              {data.sanityAbout._rawStaffIntro && (
                <PortableText blocks={data.sanityAbout._rawStaffIntro} />
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-16 mt-12 md:mt-24 pb-4 md:pb-24">
            {data.sanityAbout.staff.map((item, index) => (
              <StaffMember item={item} key={index} />
            ))}
          </div>
          <span className="w-full h-[2px] bg-[#1C6DB5] inline-block"></span>
        </div>
        <div className="container my-8 md:my-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16">
            <div className="copy md:col-start-2 text-[#1C6DB5] text-[26px] leading-[38px] lg:text-[35px] lg:leading-[48px]">
              {data.sanityAbout._rawGetInTouch && (
                <PortableText blocks={data.sanityAbout._rawGetInTouch} />
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
  
}

export default AboutPage
